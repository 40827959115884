
  const ButtonIconBorder = (props) => {
    const ButtonIconBorderdata =  {
        name: props.name,
        svgPathAttributes: props.svgPathAttributes,
        styleClasses: props.styleClasses
      }
    return (
      <button
        className="button"
      >
        <div className="text-base">{ButtonIconBorderdata.name}</div>
        {/* Icon  */}
        <svg
          className="button-svg ml-2 p-1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          {ButtonIconBorderdata.svgPathAttributes ? (
            <path {...ButtonIconBorderdata.svgPathAttributes} />
          ) : (
            <path
              fill="currentColor"
              d="M4 11.25a.75.75 0 0 0 0 1.5h9.25V18a.75.75 0 0 0 1.28.53l6-6a.75.75 0 0 0 0-1.06l-6-6a.75.75 0 0 0-1.28.53v5.25H4Z"
            />
          )}
        </svg>
      </button>
    )
  }
  
  export default ButtonIconBorder
  