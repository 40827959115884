import ButtonIconBorder from "./Common/ButtonIconBorder";

const ResourcesData = [
    {
        title:"Real-World Success Stories",
        subtitle:"Discover success stories and real-world impact in our case studies, showcasing how our solutions turn challenges into triumphs.",
        link:"/resources#Case Studies",
        image:"assets/images/resources-1.png"
    },
    {
        title:"Captivating Product Videos",
        subtitle:"Immerse yourself in the extraordinary world of our products through captivating videos that bring innovation to life.",
        link:"/resources#Videos",
        image:"assets/images/resources-2.png"
    },
    {
        title:"Latest Updates and Developments",
        subtitle:"Stay in the know with the latest updates and exciting developments in our world.",
        link:"/resources#News Releases",
        image:"assets/images/resources-3.png"
    },
    {
        title:"In-Depth Insights and Thought Leadership",
        subtitle:"Explore in-depth insights and thought leadership in our whitepapers, where innovation meets expertise to shape the future of your understanding.",
        link:"/resources#Whitepapers",
        image:"assets/images/resources-4.png"
    },
    {
        title:"Expert Perspectives and Narratives",
        subtitle:"Dive into insightful narratives and expert perspectives in our blog section, where each post unveils a new chapter of inspiration and knowledge.",
        link:"/resources#Blogs",
        image:"assets/images/resources-5.png"
    },
  
]

const Resources = () => {
  return (
    <div className='hero-section serv-page-hero serv-bg' id='resources'>
      <div className='container h-100'>
        <div className='common-page-main-section' style={{ padding: "2rem" }}>
          <div className='common-page-hero-title'>Discover Our Comprehensive Resources</div>
          <div className='common-page-hero-subtitle'>
             Explore Our Resources: Case Studies, Videos, News, Whitepapers, and Blogs
          </div>
        </div>
        {/* service cards */}
        <div className='resource-card-container'>
          {ResourcesData.map((item) => {
            return (
              <div className='resource-card'>
                <div className='service-card-img'>
                  <img src={item.image} className='img-fluid' alt='serv-img' />
                </div>
                <div className='resource-section-card-title'>{item.title}</div>
                <div className='service-card-subtitle' style={{fontSize:"15px"}}>{item.subtitle}</div>
                <a className="service-card-button" href={item.link}>
                  <ButtonIconBorder
                    name='Learn more'
                    svgPathAttributes={{
                      fill: "currentColor",
                      d: "M4 11.25a.75.75 0 0 0 0 1.5h9.25V18a.75.75 0 0 0 1.28.53l6-6a.75.75 0 0 0 0-1.06l-6-6a.75.75 0 0 0-1.28.53v5.25H4Z",
                    }}
                  />
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Resources;
