const BASE_URL = 'https://s3.ap-south-1.amazonaws.com/';
const URLS = {
    getcareers: BASE_URL + 'search.onestop.ai/Json/careers.json',
    getCaseStudies: BASE_URL + 'search.onestop.ai/Json/case_studies.json',
    getCustomers: BASE_URL + 'search.onestop.ai/Json/customers.json',
    getDemoApps: BASE_URL + 'search.onestop.ai/Json/demo_apps.json',
    getJobApplications: BASE_URL + 'search.onestop.ai/Json/job_applications.json',
    getmarquees: BASE_URL + 'search.onestop.ai/Json/marquees.json',
    getTeamMembers: BASE_URL + 'search.onestop.ai/Json/team_members.json',
}

export default URLS;
