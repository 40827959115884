import Footer from "../Footer/Footer";
import { Fragment } from "react";
import Navigation from "../Navigation/Navigation";
// import privacypolicy from './privacypolicy.css';
const PrivacyPolicy = () => {
  return (
    <Fragment>
      <div className='loader'>
        <div className='loader-inner'>
          <img
            src='assets/images/loader.gif'
            alt='Loader GIF'
            className='loader-img'
            loop='infinite'
          />
        </div>
      </div>
      <div>
        <Navigation />
      </div>
      {/* <!-- Hero Section Start  --> */}
      <div className='' id=''>
        <div className='container'>
          <div className='row width-80-desktop-block '>
            <div className='col-md-12'>
              <div className='text-left  mb-5' style={{ paddingTop: 220 }}>
                <h4 class='text-uppercase'>
                  <strong>PREAMBLE:</strong>
                </h4>
                <p>&nbsp;</p>
                <p>
                  AlgoAnalytics takes Privacy with utmost seriousness and care.
                  While carrying out global business operations, we take utmost
                  care and due diligence to ensure Privacy aspects of every
                  individual who interacts with us is taken care of, by design
                  and by default, in alignment with all applicable laws and
                  regulations.
                </p>

                <p>
                  Our Privacy notice below explains what data we collect and
                  what we do with it. It also explains how the rights of every
                  individual whose personal information we are dealing with, is
                  effectively protected and fulfilled. Our Privacy Policy
                  explains:
                </p>
                <ul class='custom-list'>
                  <ol style={{ listStyleType: "square" }}>
                    <li>
                      The nature and extent of personal information we collect,
                      how we collect it and why we collect it
                    </li>
                    <li>How we use that information</li>
                    <li>
                      The options and methods we offer to individuals to fulfil
                      their right towards Privacy, including provisions to
                      access and update information
                    </li>
                  </ol>
                </ul>
                <p>
                  We’ve tried to keep it as simple and concise as possible.
                  Please review the Policy before you share any personal
                  information with us. By using the website further and
                  providing us with your personal information, you are
                  demonstrating your consent for the same. In accordance with
                  the applicable laws and regulations, we will be seeking your
                  consent on collection and usage of specific personal
                  information in specific context, as required.
                </p>
                <h4 class='mg-b30 text-uppercase'>
                  <strong>
                    OUR COMMITMENT TO PRIVACY ACTS AND REGULATIONS:
                  </strong>
                </h4>
                <p>
                  We are committed to highest levels of compliance towards the
                  applicable Privacy acts and regulations where we offer our
                  Services to, including the EU regulation GDPR. We have also
                  established strong Information Security and Data Protection
                  frameworks within our business operations including a strong
                  Information Security Management System certified towards
                  ISO/IEC 27001:2013 and SOC 2 Type II compliant which ensures
                  the Security, Availability, Processing Integrity,
                  Confidentiality and Privacy of data.
                </p>
                <p>
                  Please be aware that these could be reviewed and updated to
                  make our practices towards Privacy better and stronger.
                </p>
                <p>
                  If you have any questions contact us at&nbsp;
                  <a href='/contact'>privacy@algoanalytics.com</a>.
                </p>
                <p>&nbsp;</p>
                <h4>Privacy Notice:</h4>
                <p>
                  We collect and process personal information as a Data
                  Controller, in the following areas:
                </p>
                <ul class='custom-list'>
                  <ol style={{ listStyleType: "square" }}>
                    <li>
                      As part of our Marketing and Sales process, to market and
                      sell our Solutions and Services
                    </li>
                    <li>
                      During your visit to our website, either through
                      designated web-forms or through automated means such
                      as&nbsp;
                      <a
                        href='https://www.algoanalytics.com/cookie-policy'
                        target='_blank'
                        rel='noopener noreferrer'>
                        cookies
                      </a>
                    </li>
                    <li>
                      As a part of Recruitment Process, in order to engage
                      suitable human resource professionals into our global team
                    </li>
                  </ol>
                </ul>
                <p>
                  The details of the policies and controls governing these are
                  given in the below sections.
                </p>
                <p>
                  We also collect and process personal information as a part of
                  our internal processes such as HR and Payroll, and the
                  policies and controls governing the same are established
                  internally within the organization.
                </p>
                <p>
                  AlgoAnalytics Provides Data Analytics and Decision Science
                  Solutions and Services to our global customers. Though
                  AlgoAnalytics does not engage individuals directly nor collect
                  personal information directly from them in this process, the
                  personal information collected by our customers might be
                  processed by AlgoAnalytics, directly or indirectly, as a part
                  of the fulfillment of the Solutions and Service Delivery. Such
                  processing is carried out, on behalf of our customers. In this
                  way, AlgoAnalytics acts as Data Processor of Personal
                  information, to the extent of, and strictly bound and governed
                  by the Data Processing Agreements (DPA) with our customers.
                </p>
                <p>
                  <b>
                    <a
                      href='https://www.algoanalytics.com/cookie-policy'
                      target='_blank'
                      rel='noopener noreferrer'>
                      Cookies:
                    </a>
                  </b>
                  &nbsp;We use&nbsp;
                  <a
                    href='https://www.algoanalytics.com/cookie-policy'
                    target='_blank'
                    rel='noopener noreferrer'>
                    cookies
                  </a>
                  &nbsp;to store visitor preferences, record session
                  information, record user-specific information on what pages
                  users access or visit, alert visitors to new areas that we
                  think might be of interest to them when they return to our
                  website, record past activity at a website in order to provide
                  better service when visitors return to website, ensure that
                  visitors are not repeatedly sent the same banner ads, and to
                  customize web page content based on visitors’ browser type or
                  other information that the visitor sends.
                </p>
                <p>
                  Most browsers are set up to accept cookies, but you can change
                  your settings to have your browser notify you when you receive
                  a new cookie or you can change your settings to refuse to
                  accept cookies.
                </p>
                <p>
                  <b>Children:&nbsp;</b>AlgoAnalytics does not provide services
                  or sell products/services to, nor engages or recruits/employs
                  children under the age of 18. Hence, as a Data Controller,
                  there are no avenues where AlgoAnalytics collects and
                  processes personal data of children below 18.
                </p>
                <p>
                  By default, even as a Data Processor, we do not receive and/or
                  process any personal data of children below 18 from our
                  customers. If we discover that we have received any
                  information from a child under the age of 18 in violation of
                  this policy, we will delete that information immediately. If
                  you believe we have received any information from or about
                  anyone under the age of 18, please contact us immediately.
                </p>
                <p>
                  <b>Information that we collect:</b>
                </p>
                <p>
                  As a Data Controller, we collect personal information in the
                  following areas and ways:
                </p>
                <ul class='custom-list'>
                  <ol style={{ listStyleType: "square" }}>
                    <li>
                      Details collected through specific web-forms on our
                      website during your visit to our website. The information
                      collected through these web-forms usually includes
                      non-sensitive information such as Name, Country, Industry,
                      Organization, Job Title, Department, Business and/or
                      personal email ids, Contact numbers and Resumes.
                    </li>
                    <li>
                      Technical and other details are automatically collected
                      during your visit to our website. This information is
                      generally limited to information in Cookies, IP address,
                      Web browser details. Please refer to the section on
                      Cookies below for specific information on the same. Any
                      additional information collected in specific cases will be
                      bound by specific notification and consent from user(s) as
                      well as the processing agreement with the customer.
                    </li>
                    <li>
                      We might collect your personal information through various
                      sources such as our marketing platforms like Hubspot and
                      Instapage as well as external sources such as LinkedIn and
                      Marketing Service providers/partners, as a part of our
                      Marketing and Sales process.
                    </li>
                    <li>
                      As a part of our recruitment process, we might also
                      collect your personal information from other sources such
                      as LinkedIn and Job Portals. In addition to the personal
                      information mentioned above, specific details such as
                      Education, Qualification, Family, Employment and Salary
                      details might also be sought and collected from you during
                      the process, subject to specific consent from your side
                      for the same.
                    </li>
                  </ol>
                </ul>
                <p>
                  As a Data Processor, we might receive personal information via
                  our global customers, as a part of, and as required for the
                  fulfillment of our Solution and/or Service commitments to
                  them. All the data collection through such processes is
                  strictly governed by the specific Scope of Work and Data
                  Processing Agreement with those customers.
                </p>
                <p>
                  <b>Use of the information collected:</b>
                </p>
                <ul>
                  <li style={{ listStyleType: "none" }}>
                    <ul>
                      <li>
                        Your personal Data collected through our web-forms will
                        be used only for the designated purposes, for which it
                        was collected, and for purposes for which your consent
                        was taken
                      </li>
                      <li>
                        The technical and related user end data collected will
                        be used for the following purposes:
                      </li>
                    </ul>
                  </li>
                </ul>
                <p>
                  Ensuring customized and optimum presentation and performance
                  of our website for you
                </p>
                <ul>
                  <li style={{ listStyleType: "none" }}>
                    <ul>
                      <li style={{ listStyleType: "none" }}></li>
                    </ul>
                  </li>
                </ul>
                <p>
                  Monitoring and analyzing to ensure effective and efficient
                  system performance and usage of our website and related
                  services
                </p>
                <ul>
                  <li style={{ listStyleType: "none" }}>
                    <ul>
                      <li style={{ listStyleType: "none" }}></li>
                    </ul>
                  </li>
                </ul>
                <p>
                  Identification and proactive handling of any technical issues
                </p>
                <ul>
                  <li style={{ listStyleType: "none" }}>
                    <ul>
                      <li style={{ listStyleType: "none" }}></li>
                    </ul>
                  </li>
                </ul>
                <p>Troubleshooting and support purposes</p>
                <p>
                  In this process, AlgoAnalytics also will be using third-party
                  services and tools, where necessary, such as Google analytics,
                  Google Search Console and so on.
                </p>
                <ul>
                  <li style={{ listStyleType: "none" }}>
                    <ul>
                      <li>
                        Personal information collected from other sources for
                        Marketing and Sales purposes will be used only for
                        legitimate purposes, in compliance with the applicable
                        laws and regulations, subject specific aspects such as:
                      </li>
                    </ul>
                  </li>
                </ul>
                <p>
                  Demonstrable relevance, context and value of the communication
                  to you
                </p>
                <ul>
                  <li style={{ listStyleType: "none" }}>
                    <ul>
                      <li style={{ listStyleType: "none" }}></li>
                    </ul>
                  </li>
                </ul>
                <p>
                  Explicit consent sought from you at first contact to continue
                  the conversation
                </p>
                <ul>
                  <li style={{ listStyleType: "none" }}>
                    <ul>
                      <li style={{ listStyleType: "none" }}></li>
                      <li>
                        The data collected for Recruitment purpose will only be
                        used for the purposes and processes related to the same,
                        as per the consent received from you, and in accordance
                        with applicable laws and regulations
                      </li>
                      <li>
                        The data in the AlgoAnalytics system is also subject to
                        periodic and ongoing Backup. The backup data is further
                        used/processed only for the following purposes:
                      </li>
                    </ul>
                  </li>
                </ul>
                <p>Trouble-shooting and data recovery purposes</p>
                <ul>
                  <li style={{ listStyleType: "none" }}>
                    <ul>
                      <li style={{ listStyleType: "none" }}></li>
                    </ul>
                  </li>
                </ul>
                <p>Backup and Recovery testing/verifications</p>
                <ul>
                  <li style={{ listStyleType: "none" }}>
                    <ul>
                      <li style={{ listStyleType: "none" }}></li>
                      <li>
                        We don’t profile the users or monitor the behavior of
                        users using the personal information either manually or
                        in an automatic manner, for any purpose other than
                        legitimately required as a part of delivering and
                        supporting the designated service from us
                      </li>
                      <li>
                        We don’t use the personal data collected/provided to us
                        for any purpose other than:
                      </li>
                    </ul>
                  </li>
                </ul>
                <p>Those which are detailed in this Privacy Policy, and</p>
                <ul>
                  <li style={{ listStyleType: "none" }}>
                    <ul>
                      <li style={{ listStyleType: "none" }}></li>
                    </ul>
                  </li>
                </ul>
                <p>
                  Those for which specific consent has been received from you,
                  and
                </p>
                <ul>
                  <li style={{ listStyleType: "none" }}>
                    <ul>
                      <li style={{ listStyleType: "none" }}></li>
                    </ul>
                  </li>
                </ul>
                <p>
                  Those which are explicitly included in our formal Data
                  processing agreement with our Customers as Data controllers
                </p>
                <p>
                  <b>To whom would we disclose this Personal Data?</b>
                </p>
                <ul class='custom-list'>
                  <ol style={{ listStyleType: "square" }}>
                    <li>
                      All the personal Data within the context of AlgoAnalytics
                      will be disclosed to and be accessible by only limited,
                      designated personnel within AlgoAnalytics, as governed by
                      the Data Processing agreement with our customers as Data
                      Controllers. These personnel could be part of any of the
                      registered companies within the AlgoAnalytics corporate
                      and its subsidiary companies.
                    </li>
                    <li>
                      Some of the personal data may also be shared with
                      designated Service/Solution providers who act as
                      sub-processors for designated purposes, such as Marketing
                      services, recruitment and Payroll Services, and other
                      technical services such as emails, spam filtering,
                      analytics, monitoring and troubleshooting etc. We
                      contractually require our agents, service providers, and
                      affiliates who may process personal data related to the
                      Services to provide the same level of protection for
                      personal data as required under the Principles and
                      applicable laws and regulations. AlgoAnalytics currently
                      does not transfer personal data to a third party for the
                      third party’s own use, but only for Mu Sigma’s purpose as
                      a part of our legitimate business purpose as a Data
                      Controller and Data Processor.
                    </li>
                    <li>
                      In accordance with our legal obligations, we may also
                      transfer Data, subject to lawful requests and
                      requirements, to public authorities for law enforcement or
                      national security purposes, where required and applicable.
                      We may also disclose Customer Data (including any personal
                      data), where otherwise required by law.
                    </li>
                  </ol>
                </ul>
                <p>
                  <b>Data Storage and Transfer:</b>
                </p>
                <ul class='custom-list'>
                  <ol style={{ listStyleType: "square" }}>
                    <li>
                      The Data collected will be stored in the Physical and/or
                      cloud based Servers and Databases owned and managed by
                      AlgoAnalytics and/or our designated partners
                    </li>
                    <li>
                      The data might be stored and/or transferred within or
                      outside the region of collection, to fulfill the agreed
                      and lawful processing. However, any transfer of data
                      outside the region of data subjects will be done in
                      compliance with the applicable Privacy acts and
                      regulations.
                    </li>
                  </ol>
                </ul>
                <p>
                  <b>Data Retention:</b>
                </p>
                <ul class='custom-list'>
                  <ol style={{ listStyleType: "square" }}>
                    <li>
                      We apply a general rule of keeping personal data only for
                      as long as required to fulfill the purposes for which it
                      was collected
                    </li>
                    <li>
                      The retention of Any customer specific data including
                      Personal Data in our systems will be limited to the terms
                      agreed in our contracts/agreements with our customers, as
                      well as the requirements in applicable laws and
                      regulations
                    </li>
                    <li>
                      AlgoAnalytics doesn’t retain the data beyond the tenure
                      required by the specific purpose for which it was
                      collected, by our Customer and/ or any applicable laws/
                      regulations
                    </li>
                  </ol>
                </ul>
                <p>
                  <b>Data Protection and Security:</b>
                </p>
                <p>
                  AlgoAnalytics takes Data Security including that of personal
                  data very seriously. All our systems and processes are
                  planned, designed and managed by keeping Security and Privacy
                  in mind.
                </p>
                <p>
                  A robust information Security Management System (ISMS) is
                  established within AlgoAnalytics that governs the systems and
                  practices. This ISMS is established and managed in alignment
                  with global best practices and certified towards ISO/IEC
                  27001:2013 standard. The system is subject to strong controls
                  including ongoing monitoring, periodic security testing,
                  internal/external audits and verifications.
                </p>
                <p>
                  We contractually require our agents, service providers, and
                  affiliates who may process data related to the Services to
                  provide the same level of protection for data as required
                  under the Principles and applicable laws and regulations.
                </p>
                <p>
                  <b>Your Rights as a Data Subject:</b>
                </p>
                <p>
                  Subject to applicable law(s) and regulations, you may have
                  some or all the following rights available to you in respect
                  of your personal data that is residing with us:
                </p>
                <ul class='custom-list'>
                  <ol style={{ listStyleType: "square" }}>
                    <li>
                      To obtain a copy of your personal data together with
                      information about how and on what basis that personal data
                      is processed
                    </li>
                    <li>
                      To rectify inaccurate personal data (including the right
                      to have incomplete personal data completed)
                    </li>
                    <li>
                      To request for erasing your personal data residing with
                      us, subject to specific context and terms and conditions
                    </li>
                    <li>
                      To request for a restriction on processing personal data
                      under certain circumstances
                    </li>
                    <li>
                      To port your data in machine-readable format to a third
                      party (or to you)
                    </li>
                    <li>
                      To withdraw your consent to us processing your personal
                      data (where that processing is based on your consent)
                    </li>
                    <li>
                      To lodge a complaint with the supervisory authority in
                      your region
                    </li>
                  </ol>
                </ul>
                <p>
                  In relation to any such rights related aspects on Privacy,
                  please contact us at <a href='/contac'>Contact Us</a>
                </p>
                <p>
                  Please note that we may request proof of identity, and we
                  reserve the right to charge a fee where permitted by law,
                  especially if your request is manifestly unfounded or
                  excessive. We will endeavor to respond to your request within
                  all applicable timeframes.
                </p>
                <p>
                  In the case of Personal Information received from, and
                  processed on behalf of our customers, since we are not the
                  Data Controller, the applicability, details of methods and
                  terms for requesting and fulfilling these rights will be
                  defined and provided by our customers. AlgoAnalytics is fully
                  committed to enable and support our customers in this aspect,
                  as per the Data processing and service agreement(s) with our
                  customers.
                </p>
                <p>
                  If you contact us regarding Data collected by or on behalf of
                  our customers for which we are acting as a data processor, we
                  will attempt to refer your request to the relevant Customer
                  who acts as the data controller for your personal data.
                </p>
                <p>
                  <b>Further Questions/Information:</b>
                </p>
                <p>
                  If you have any questions or need further information
                  regarding this policy, you may contact us via the means
                  provided on this site.
                </p>
                <p>
                  These terms and conditions shall be governed by and construed
                  in accordance with the laws of India and any dispute shall be
                  referred to&nbsp;<a href='mailto:privacy@algoanalytics.com'>privacy@algoanalytics.com</a>
                  &nbsp;and&nbsp;<a href='mailto:legal@algoanalytics.com'>legal@algoanalytics.com</a>.
                </p>
                <p>
                  Last updated on 16 January 2023.
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
};
export default PrivacyPolicy;
