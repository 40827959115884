import ButtonIconBorder from "./Common/ButtonIconBorder";
import React, { useState } from "react";

const IndustryData = [
  {
    mainTitle:"Banking & Finance",
    title: "Revolutionizing Banking & Finance with AI/ML",
    description:
      "Explore endless possibilities with data-driven AI/ML across multiple banking and finance functions. Enhance risk management, customer service, and operational efficiency.",
    link: "/demoapp",
    CaseStudyLink: "/resources#Case Studies",
    image: "assets/images/ind-img2.png",
    altText:"Image of a banking employee",
  },
  {
    mainTitle:"Manufacturing",
    title: "AI/ML in Manufacturing",
    description:
      "Leverage AI/ML to optimize production processes, improve quality control, and reduce downtime.",
    link: "/demoapp",
    CaseStudyLink: "/resources#Case Studies",
    image: "assets/images/ind-img1.png",
    altText: "Image of a machine in manufacturing plant",
  },
  {
    mainTitle:"Legal",
    title: "Legal Industry Transformation with AI/ML",
    description:
      "Utilize AI/ML for legal research, contract analysis, and compliance monitoring.",
    link: "/demoapp",
    CaseStudyLink: "/resources#Case Studies",
    image: "assets/images/ind-img3.png",
    altText: "Image of a gavel and law books representing the legal industry",
  },
  {
    mainTitle:"Retail",
    title: " AI/ML in Retail",
    description:
      "Enhance customer experience, optimize inventory management, and personalize marketing strategies with  AI/ML",
    link: "/demoapp",
    CaseStudyLink: "/resources#Case Studies",
    image: "assets/images/ind-img4.png",
    altText: "Image of a modern retail store with digital price tags and interactive displays",
  },
  {
    mainTitle:"Healthcare",
    title: "AI/ML in Healthcare",
    description:
      "Improve patient outcomes, streamline administrative tasks, and advance medical research with AI/ML.",
    link: "/demoapp",
    CaseStudyLink: "/resources#Case Studies",
    image: "assets/images/ind-img5.png",
    altText: "Image of a healthcare professional using advanced medical equipment",
  },
  {
    mainTitle:"Smart City",
    title: "Building Smart Cities with AI/ML",
    description:
      "Implement AI/ML to enhance urban planning, traffic management, and public safety.",
    link: "/demoapp",
    CaseStudyLink: "/resources#Case Studies",
    image: "assets/images/ind-img6.png",
    altText:"Image of a futuristic cityscape with smart infrastructure and connected devices",
  },
];

const Industry = (props) => {
  const [activeItem, setActiveItem] = useState(0);
  

  return (
    <div className='industry-section'>
      <div className='container'>
        <div className='common-page-main-section'>
          <div className='common-page-hero-title'>Empowering Industries with AI/ML Solutions</div>
          <div className='common-page-hero-subtitle'>
            Enhance your decision making capabilities with powerful insights and
            augmented intelligence with expertise in AI.
          </div>
        </div>

        <div className='industry-container'>
          <div className='industry-header'>
            {/* horizontal list of industry names */}
            <div className='industry-header-list'>
              {IndustryData.map((item, index) => {
                return (
                  <div
                    className={`industry-header-item ${
                      index === activeItem ? "active" : ""
                    }`}
                    onClick={() => setActiveItem(index)}>
                    {item.mainTitle}
                  </div>
                );
              })}
            </div>
          </div>
          <div className='industry-body'>
            {/* body contain industry title, description and 2 buttons on left side and image on right side */}
            {IndustryData.map((item, index) => {
              return (
                <div
                  className={`industry-body-item ${
                    index === activeItem ? "active" : ""
                  }`}>
                  <div className='industry-body-text'>
                    <div className='industry-body-title'>{item.title}</div>
                    <div className='industry-body-description'>
                      {item.description}
                    </div>
                    <div className='industry-body-buttons'>
                      <div className='industry-body-button'>
                        <a href={item.link}
                        // onClick={() => {
                        //   props.filterFn(item.title)
                        // }}
                        >
                          <ButtonIconBorder
                            name='Try Demo'
                            svgPathAttributes={{
                              fill: "currentColor",
                              d: "M4 11.25a.75.75 0 0 0 0 1.5h9.25V18a.75.75 0 0 0 1.28.53l6-6a.75.75 0 0 0 0-1.06l-6-6a.75.75 0 0 0-1.28.53v5.25H4Z",
                            }}
                            // styleClasses='justify-start'
                          />
                        </a>
                      </div>
                      <div className='industry-body-button'>
                        <a href={item.CaseStudyLink}>
                          <ButtonIconBorder
                            name='View Case Studies'
                            svgPathAttributes={{
                              fill: "currentColor",
                              d: "M4 11.25a.75.75 0 0 0 0 1.5h9.25V18a.75.75 0 0 0 1.28.53l6-6a.75.75 0 0 0 0-1.06l-6-6a.75.75 0 0 0-1.28.53v5.25H4Z",
                            }}
                            // styleClasses='justify-start'
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className='industry-body-image'>
                    <img
                      src={item.image}
                      alt= {item.altText}
                      className='industry-image'
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Industry;
