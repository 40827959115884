import React, { useState } from "react";
import { Navigate, Route, Routes } from "react-router";

import Home from "./components/Home/Home";
import Navigation from "./components/Navigation/Navigation";
import Footer from "./components/Footer/Footer";
import Services from "./components/Services/Services";
import Products from "./components/Products/Products";
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import Careers from "./components/Careers/Careers";
// import Casestudy from "./components/Casestudy/Casestudy";
import DemoApp from "./components/Demoapp/DemoApp";
import EmergingTech from "./components/EmergingTech/EmergingTech";
import Terms from "./components/termsconditon/terms";
import PrivacyPolicy from "./components/privacypolicy/Privcypolicy";
import SingleService from "./components/SingleService";
import Resource from "./components/Resources/Resources";
import SingleCaseStudy from "./components/SingleCaseStudy";

const AppRoutes = () => {
  const [filter, setFilter] = useState("");
  const [filterdata, setFilterData] = useState("");
  const [filterservices, setFilterServices] = useState("");
  const filterFn = (filters) => {
    setFilter(filters);
  };
  const filterFnData = (filterdata) => {
    setFilterData(filterdata);
  };
  const filterFnServ = (filterservices) => {
    setFilterServices(filterservices);
  };
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/navigation' element={<Navigation />} />
      <Route
        path='/services'
        element={<Services filterFnServ={filterFnServ} />}
      />
      <Route path='/footer' element={<Footer />} />
      <Route path='/products' element={<Products filterFn={filterFn} />} />
      <Route path='/about' element={<About />} />
      <Route path='/resources' element={<Resource />} />
      <Route path='/contact' element={<Contact />} />
      <Route path='/careers' element={<Careers />} />
      {/* // <Route path="/casestudy" element={<Casestudy filter={<Routefilter/>} />} /> */}
      <Route
        path='/demoapp'
        element={
          <DemoApp
            filter={filter}
            filterdata={filterdata}
            filterservices={filterservices}
          />
        }
      />
      <Route
        path='/emergingtech'
        element={
          <EmergingTech
            filterFnData={filterFnData}
            filterFnServ={filterFnServ}
          />
        }
      />
      <Route path='/terms&conditions' element={<Terms />} />
      <Route path='/privacypolicy' element={<PrivacyPolicy />} />
      <Route
        path='/ai-ml-solutions'
        element={
          <SingleService
            metaTitle='Custom AI/ML Solutions for Strategic Business Outcomes'
            metaDescription='Discover our bespoke AI/ML solutions tailored to achieve your strategic business goals. We specialize in predictive analytics AI-based process automation and more. Learn how our solutions can transform your business operations.'
            title='Custom AI/ML Solutions for Strategic Business Outcomes'
            description='We collaborate with clients to create bespoke AI solutions aimed at achieving strategic business outcomes. 
            Our expertise spans across various industries addressing complex problems with AI algorithms tailored for both structured and unstructured data. 
            We excel in classical Machine Learning and Deep Learning algorithm development.'
            image='assets/images/Services-1.jpeg'
            altText='AI/ML Solutions'
            flowchartDetails={[
              {
                flowchartImage: "assets/images/flowchart-5.png",
                altText: "Predictive Analytics Flowchart",
                flowchartTitle: "Core Offerings - Predictive Analytics",
                flowchartSubtitle:
                  "We develop bespoke AI solutions for Predictive Analytics including Churn Prediction Recommendation Systems Predictive Maintenance Forecasting for Manufacturing and Smart City Analytics (Crowd Prediction Surveillance).",
              },
              {
                flowchartImage: "assets/images/flowchart-6.png",
                altText: "AI-based Process Automation Flowchart",
                flowchartTitle: "Core Offerings - AI-based Process Automation",
                flowchartSubtitle:
                  "We create bespoke text and Image/Video analytics solutions including Document Parsing Semantic Analysis Warranty/Claims Analytics Visual Inspection/Defect Detection in Manufacturing Car Damage Detection Smart KYC and Signature Detection.",
              },
            ]}
            valueDetails={[
              {
                value:
                  "Our analytics solution enhances crowd management and facility utilization for a global tourist destination",
              },
              {
                value:
                  "Reduced customer churn for an auto insurance company by up to 16% using our recommendation engine. ",
              },
              {
                value:
                  "Achieved a 5.7X projected gain in basket revenue for a travel portal using our AI engine.",
              },
              {
                value:
                  "Reduced overall insurance claim processing time by 20% with AI-based smart inspection for car damage detection.",
              },
            ]}
          />
        }
      />
      <Route
        path='/cloud-deployment'
        element={
          <SingleService
            metaTitle='Comprehensive Cloud AI/ML Deployment Services'
            metaDescription='Explore our comprehensive suite of AI/ML cloud deployment services including DevOps MLOps FinOps and SecOps designed to optimize your business operations and ensure robust security. Learn more about our expertise.'
            title='Comprehensive Cloud AI/ML Deployment Services'
            description='We offer a comprehensive suite of cloud services that seamlessly integrate into diverse business environments. Our AI/ML cloud deployment services ensure agile development cost efficiency and top-tier security.'
            image='assets/images/Services-2.jpeg'
            altText='Cloud Deployment Services'
            flowchartDetails={[
              {
                flowchartImage: "assets/images/flowchart-1.png",
                altText: "DevOps Flowchart",
                flowchartTitle: "Core Offerings - DevOps",
                flowchartSubtitle:
                  "Expertise in the implementation of DevOps practices delivering end-to-end scalable and automated solutions that prioritize efficient development continuous integration and seamless deployment pipelines.",
              },
              {
                flowchartImage: "assets/images/flowchart-2.png",
                altText: "MLOps Flowchart",
                flowchartTitle: "Core Offerings - MLOps",
                flowchartSubtitle:
                  "Expertise in MLOps to deploy maintain and track AI/ML models' performance reliably and efficiently. We also automate model retraining as required ensuring continuous improvement.",
              },
              {
                flowchartImage: "assets/images/flowchart-3.png",
                altText: "FinOps Flowchart",
                flowchartTitle: "Core Offerings - FinOps",
                flowchartSubtitle:
                  "Expertise in billing optimizations and cost reductions for cloud deployments. Our FinOps solutions ensure that clients maximize their AI investments while minimizing expenses.",
              },
              {
                flowchartImage: "assets/images/flowchart-4.png",
                altText: "SecOps Flowchart",
                flowchartTitle: "Core Offerings - SecOps",
                flowchartSubtitle:
                  "Expertise in integrating comprehensive security into operations. Our proactive threat mitigation and incident response strategies efficiently protect infrastructure and data ensuring compliance with industry regulations.",
              },
            ]}
            valueDetails={[
              {
                value:
                  "Optimized cloud services cost for our SaaS platform (AlgoFabric) by over 34% within 3 months.",
              },
              {
                value:
                  "Delivered end-to-end AI implementation on cloud for “Lead Analytics”  for a large US financial services client.",
              },
              {
                value:
                  "Executed end-to-end AI implementation on cloud for a smart city project for a foreign government.",
              },
              {
                value:
                  "Developed and deployed homegrown SaaS platform (AlgoFabric) on cloud.",
              },
            ]}
          />
        }
      />
      <Route
        path='/generative-ai'
        element={
          <SingleService
            metaTitle='Innovative Generative AI Solutions for Business Transformation'
            metaDescription='Discover our expertise in Generative AI and LLM frameworks like OpenAI Llama and LangChain. Explore our solutions for AI-based process automation chatbot development and consulting services. Transform your business with GenAI.'
            title='Innovative Generative AI Solutions for Business Transformation'
            description='We specialize in working with LLM frameworks like OpenAI Llama and LangChain. 
            Our technology accelerators built using the latest LLM models enable rapid deployment of GenAI solutions for various businesses. 
            We also offer cloud-based AI services through Amazon BedRock and Azure OpenAI. Our ongoing research on multi-modal GenAI models ensures we stay at the forefront of this cutting-edge technology.'
            image='assets/images/Services-3.jpeg'
            altText='Generative AI Solutions'
            iframeSrcLink='https://www.youtube.com/embed/PnSIKurY9cs'
            flowchartDetails={[
              {
                flowchartImage: "assets/images/flowchart-7.png",
                altText: "Solution Development and Deployment Flowchart",
                flowchartTitle:
                  "Core Offerings - Solution Development and Deployment",
                flowchartSubtitle:
                  "We specialize in customized Gen AI solutions to automate complex operations and boost corporate productivity using the latest innovations in the Gen AI space. Our innovative intelligent creative and adaptable generative AI systems provide a transformative approach to solving complex challenges.",
                flowchartStyle: "60%",
              },
              {
                flowchartImage: "assets/images/flowchart-8.png",
                altText: "LLM-Based Chatbot Developments Flowchart",
                flowchartTitle:
                  "Core Offerings - LLM-Based Chatbot Developments",
                flowchartSubtitle:
                  "Expertise in developing LLM-based Document Q&A chatbots for various domains including travel healthcare and finance. Our chatbots integrate with knowledge management systems to access multiple documents and repositories.",
                flowchartStyle: "60%",
              },
              {
                flowchartImage: "assets/images/flowchart-9.png",
                altText:
                  "Gen AI & LLM Consulting and Solution Architectures Flowchart",
                flowchartTitle:
                  "Core Offerings - Gen AI & LLM Consulting and Solution Architectures",
                flowchartSubtitle:
                  "Expertise in Gen AI strategy and consulting including use case recognition and feasibility evaluation technology assessment and choice technical architecting and AI deployment tailored to your business's specific needs goals and challenges.",
                flowchartStyle: "60%",
              },
            ]}
            valueDetails={[
              {
                value:
                  "Developed process automation solutions using LLM for a large Japanese company.",
              },
              {
                value:
                  "Created LLM-based chatbots and a customized document retrieval system.",
              },
              {
                value:
                  "Built a Digital Twin of a Human based on LLM for a Japanese technology company.",
              },
              {
                value:
                  "Offered several LLM-based tech accelerators on our Onestop.ai portal.",
              },
            ]}
            techStack={[
              {
                techImage: "assets/images/tech-stack-1.png",
              },
              {
                techImage: "assets/images/tech-stack-2.png",
              },
              {
                techImage: "assets/images/tech-stack-3.png",
              },
              {
                text: "Many More..",
              },
            ]}
          />
        }
      />
      <Route
        path='/caseStudy1'
        element={
          <SingleCaseStudy
            title='Predictive Analytics in Financial Services'
            industry='Banking & Financial Services'
            technology='AI/ML Solutions'
            dataType='Text'
            description='Successfully provided customized Predictive Analytics solutions to a number of international clients in financial services.'
            image='assets/images/case-img1.png'
            link='https://algo-case-studies.s3.ap-south-1.amazonaws.com/Predictive+Analytics.pdf'
          />
        }
      />
      <Route
        path='/caseStudy2'
        element={
          <SingleCaseStudy
            title='Our Work on Recommender Systems'
            industry='Retail'
            technology='AI/ML Solutions'
            dataType='Image'
            description='Used combination of multiple modeling techniques (matrix factorization and classification learning model) to identify best suited solution to recommend products from the product catalog based on the image of the original product.'
            image='assets/images/case-img2.png'
            link='https://algo-case-studies.s3.ap-south-1.amazonaws.com/Our+work+on+Recommender+Systems.pdf'
          />
        }
      />
      <Route
        path='/caseStudy3'
        element={
          <SingleCaseStudy
            title='Our Work in Legal Analytics'
            industry='Legal'
            technology='Deep Learning / AI'
            dataType='text'
            //description for legal analytics
            description='Automated/semi-automated manual labour to read and extract meaningful information like name place location entity dates amounts etc. from legal contracts using Natural Language Processing (NLP).'
            image='assets/images/case-img3.png'
            link=' https://algo-case-studies.s3.ap-south-1.amazonaws.com/Legal+Analytics.pdf'
          />
        }
      />
      <Route
        path='/caseStudy4'
        element={
          <SingleCaseStudy
            title='Automated Quality Control to Detect Defects in Painted Parts'
            industry='Manufacturing'
            technology='Deep Learning / AI'
            dataType='Image'
            description='Successfully developed a computer vision model that increased accuracy in identifying defects in painted parts resulting in a significant improvement in quality control for our client.'
            image='assets/images/case-img4.png'
            link='https://algo-case-studies.s3.ap-south-1.amazonaws.com/DefectDetection.pdf'
          />
        }
      />
      <Route
        path='/caseStudy5'
        element={
          <SingleCaseStudy
            title='Our AI solutions for E-Commerce'
            industry='Retail'
            technology='AI/ML Solutions'
            dataType='Image'
            description='Take a look at our work in E-commerce Fashion AI where our innovative AI solutions helped our global retail clients enhance customer experiences.'
            image='assets/images/case-img5.png'
            link='https://algo-case-studies.s3.ap-south-1.amazonaws.com/E-commerce+solution+for+Retail.pdf'
          />
        }
      />
      <Route
        path='/caseStudy6'
        element={
          <SingleCaseStudy
            title='Cooking up ML Recipes for a No/Low Code AI Platform'
            industry='Others'
            technology='AI/ML Solutions'
            dataType='Image'
            description='Collaborated with the client to deliver end-to-end deployment of Machine Learning recipes for multiple use cases.'
            image='assets/images/case-img6.png'
            link='https://algo-case-studies.s3.ap-south-1.amazonaws.com/Cooking+up+ML+recipes+for+a+NoLow+code+AI+platform.pdf'
          />
        }
      />
      <Route
        path='/caseStudy7'
        element={
          <SingleCaseStudy
            title='Building an Automated Content Recommendation System'
            industry='Manufacturing'
            technology='AI/ML Solutions'
            dataType='Image'
            description='Built a Hybrid content recommendation system for an innovative leadership development platform focusing on vertical development. Used a combination of collaborative and content based approach using Deep learning and ML techniques.'
            image='assets/images/case-img7.png'
            link='https://algo-case-studies.s3.ap-south-1.amazonaws.com/RecommendationSystem.pdf'
          />
        }
      />
      <Route
        path='/caseStudy8'
        element={
          <SingleCaseStudy
            title='Revolutionizing Industries with Digital Twin Technology'
            industry='Manufacturing'
            technology='AI/ML Solutions'
            dataType='Image'
            description='Dive into our case study on how our innovative approach to digital twins is revolutionizing industries. Discover how we are reshaping reality and maximizing efficiency one virtual replica at a time.'
            image='assets/images/case-img8.png'
            link='https://algo-case-studies.s3.ap-south-1.amazonaws.com/Digital+Twin+Case+study.pdf'
          />
        }
      />
      {/* Catch all  */}
      <Route path='*' element={<Navigate to='/' replace />} />
    </Routes>
  );
};

export default AppRoutes;