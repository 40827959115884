import React, { useState, useEffect, useRef } from "react";
const LandingPage = () => {
  const [videoClicked, setVideoClicked] = useState(false);
  const iframeAttributes = {
    height: "300px", // Default height
    src: "https://www.youtube.com/embed/ob4zQAcoTK0",
  };

  const iframeRef = useRef(null);
  const anchorRef = useRef(null);

  useEffect(() => {
    const currentAnchor = anchorRef.current;
    // Function to handle visibility change
    const handleVisibilityChange = () => {
      if (document.hidden) {
        // Document is now hidden, pause the YouTube video
        if (iframeRef.current) {
          iframeRef.current.src = iframeRef.current.src.replace(
            "autoplay=1",
            "autoplay=0"
          );
        }
      }
    };

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // Video is in view, autoplay it
          if (iframeRef.current) {
            iframeRef.current.src = iframeRef.current.src.replace(
              "autoplay=0",
              "autoplay=1"
            );
          }
        } else {
          // Video is not in view, pause it
          if (iframeRef.current) {
            iframeRef.current.src = iframeRef.current.src.replace(
              "autoplay=1",
              "autoplay=0"
            );
          }
        }
      },
      {
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    if (currentAnchor) {
      observer.observe(anchorRef.current);
    }

    // Listen for visibility changes
    window.addEventListener("visibilitychange", handleVisibilityChange);

    // Cleanup function to remove the event listener
    return () => {
      if (currentAnchor) {
        observer.unobserve(currentAnchor);
      }
      window.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [videoClicked]);

  const handleClick = () => {
    setVideoClicked(!videoClicked);
    console.log("videoClicked");
  };

  const resetClick = () => {
    setVideoClicked(false);
  };

  const iframetransition = "iframe-transition";

  const containerStyleclass = videoClicked
    ? "containerStyleInline"
    : "containerStyle";

  return (
    <div className='container h-100'>
      <div className='landing-page-main'>
        <div className='page-inner'>
          <div className='hero-text'>
            <h1>AI/ML Solutions to Elevate and Transform your Business</h1>
            <h3>
              Revolutionize Your Decision-Making with Cutting-Edge Algo
              Analytics
            </h3>
          </div>
        </div>
        <div className={containerStyleclass}>
          <div className='video' ref={anchorRef}>
            {!videoClicked && (
              <div className='video-overlay' onClick={handleClick}></div>
            )}
            <iframe
              ref={iframeRef}
              className={iframetransition}
              // {...iframeAttributes}
              width={videoClicked ? "800px" : "500px"}
              height={videoClicked ? "400px" : "300px"}
              src={iframeAttributes.src}
              title='AlgoAnalytics: An Overview'
              frameborder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              referrerPolicy='strict-origin-when-cross-origin'
              allowFullScreen></iframe>
            {videoClicked && (
              <button className='close-btn' onClick={resetClick}>
                X
              </button>
            )}
          </div>
          {!videoClicked && (
            <div>
              <img
                className='landing-img'
                src='assets/images/home-landing-img.png'
                alt='Landing-img'
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
