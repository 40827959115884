import "./App.css";
import { Navigate, BrowserRouter as Router, useRoutes } from "react-router-dom";

import AppRoutes from "./Routes";
// import { useEffect } from 'react/cjs/react.development';

function App() {

  return (
    <div className='App'>
      <Router>
        {/* <App /> */}
        <AppRoutes />
      </Router>
    </div>
  );
}

export default App;

// npm run build
// surge ./build --domain algoanalyticsweb.surge.sh
