/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import { API_BASE_URL } from "./api_constants";

export default {
  career: {
    getCareer: () =>
      axios
        .get(API_BASE_URL + `/web/careers/get`, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          // console.log("API get career=====> res", res);
          return res.data;
        }),
  },
  team_member: {
    getTeamMember: () =>
      axios
        .get(API_BASE_URL + `/web/team_members/get`, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          // console.log("API get team=====> res", res);
          return res.data;
        }),

  },
  caseStudy: {
    getCaseStudy: () =>
      axios
        .get(API_BASE_URL + `/web/case_studies/get`, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log("API get Casstudy=====> res", res);
          return res.data;
        }),
    getAnnoucement: () =>
      axios
        .get(API_BASE_URL + `/web/marquees/get`, {
          headers: {
            "Content-Type": "application/json",
            "token": "",
          },
        })
        .then((res) => {
          // console.log("API get marquees=====> res", res);
          return res.data;
        }),
  },
  contact: {
    addContact: (data) =>
      axios
        .post(API_BASE_URL + `/web/contact_us/add`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          // console.log("API add contact =====> res", res);
          return res.data;
        }),
  },
  customer: {
    getCustomer: () =>
      axios
        .get(API_BASE_URL + `/web/customers/get`, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          // console.log("API get Customer=====> res", res);
          return res.data;
        }),
  },
  demoApp: {
    getDemoApps: () =>
      axios
        .get(API_BASE_URL + `/web/demo_apps/get`, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          // console.log("API get demo apps=====> res", res);
          return res.data;
        }),
  }
};
