/* eslint-disable eqeqeq */
import { Fragment, useEffect, useState } from "react";
import api from "../../api";
import Footer from "../Footer/Footer";
import Navigation from "../Navigation/Navigation";
// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker,
// } from "react-google-maps";
import { message } from "antd";
import "antd/dist/antd.css";
import { Helmet } from "react-helmet";

const Contact = () => {
  const [fullName, setFullName] = useState("");
  const [reason, setReason] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [howDidYouHear, setHowDidYouHear] = useState("");
  const [message2, setMessage2] = useState("");
  const [jobtitle, setJobtitle] = useState("");
  // const mapStyles = {
  //   height: "100vh",
  //   width: "100%",
  // };
  // const defaultCenter = {
  //   lat: 41.3851,
  //   lng: 2.1734,
  // };

  useEffect(() => {}, []);

  const submitDetail = () => {
    var data = {
      full_name: fullName,
      reason: message2,
      phone: phone,
      email: email,
      company_name: companyName,
      how_did_you_hear: howDidYouHear,
      message: message2,
      job_title: jobtitle,
    };
    console.log(data);
    api.contact
      .addContact(data)
      .then((result) => {
        // console.log(result);
        if (result.status == '1') {
          message.success(result.message);
          alert(result.message);
          setTimeout(() => {
            window.location.reload();
          }, 2500);
        } else {
          message.warn(result.message);
          alert(result.message);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  // const MapWithAMarker = withScriptjs(
  //   withGoogleMap((props) => (
  //     <GoogleMap defaultZoom={8} defaultCenter={{ lat: -34.397, lng: 150.644 }}>
  //       <Marker position={{ lat: -34.397, lng: 150.644 }} />
  //     </GoogleMap>
  //   ))
  // );

  return (
    <Fragment>
      {/* Meta Tags for SEO */}
      <Helmet>
        <title>Contact AlgoAnalytics - Get in Touch for Data Analytics Solutions</title>
        <meta
          name='description'
          content='Reach out to AlgoAnalytics for expert Data Analytics and AI solutions. Contact us today to learn more.'
        />
        <meta name='keywords' content='Data Analytics, AI Solutions, Business Intelligence' />
        <meta name='robots' content='index, follow' />
      </Helmet>
      {/* Loader */}
      <div className='loader'>
        <div className='loader-inner'>
          <img
            src='assets/images/loader.gif'
            alt='Loader GIF'
            className='loader-img'
            loop='infinite'
          />
        </div>
      </div>
      <Navigation />
      {/* <!-- Hero Section Start  --> */}
      <div className='contact-section contact-bg' id=''>
        <div className='container section-top'>
          <div className=' hero-inner section-top'>
            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 serv-left'>
              <div className='serv-text text-left'>
                <h2>Get in touch</h2>
                {/* <p className="">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet,
                  sed egestas justo, pellentesque tempor.
                </p> */}
              </div>
            </div>
            <div className='f-form col-lg-12 col-md-12 col-sm-12 col-xs-12'>
              <div className='con-top'>
                <div className='f-head text-left'>Select Reason:</div>
                <div
                  className='f-radio d-flex justify-content-between align-items-center'
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}>
                  <div className=''>
                    <input
                      type='radio'
                      id='test1'
                      name='radio-group'
                      value={"Business Enquiry"}
                      defaultChecked
                    />
                    <label for='test1'>Business Enquiry</label>
                  </div>
                  <div className=''>
                    <input
                      type='radio'
                      id='test2'
                      name='radio-group'
                      value={"Alliances & Partnerships"}
                    />
                    <label for='test2'>Alliances & Partnerships</label>
                  </div>
                  <div className=''>
                    <input
                      type='radio'
                      id='test3'
                      name='radio-group'
                      value={"Analysts & Media Relationships"}
                    />
                    <label for='test3'>Analysts & Media Relationships</label>
                  </div>
                  <div className=''>
                    <input
                      type='radio'
                      id='test4'
                      name='radio-group'
                      value={"Career Enquiry"}
                    />
                    <label for='test4'>Career Enquiry</label>
                  </div>
                </div>
              </div>
              <div className='f-head text-left'>Enter Details:</div>
              <div className='contact-from d-flex justify-content-between'>
                <div className='col-lg-5 col-md-12 px-0'>
                  <div className='contact-form-input contact-input-half mt-0'>
                    <input
                      type='text'
                      name='fullName'
                      value={fullName}
                      placeholder='Full Name'
                      onChange={(e) => {
                        setFullName(e.target.value);
                      }}
                      required
                    />
                  </div>
                  <div className='contact-form-input contact-input-half mt-0'>
                    <input
                      type='text'
                      name='companyName'
                      value={companyName}
                      placeholder='Organization'
                      onChange={(e) => {
                        setCompanyName(e.target.value);
                      }}
                      required
                    />
                  </div>
                  <div className='contact-form-input contact-input-half mt-0'>
                    <input
                      type='text'
                      name='reason'
                      value={jobtitle}
                      placeholder='Job Title'
                      onChange={(e) => {
                        setJobtitle(e.target.value);
                      }}
                      required
                    />
                  </div>
                  <div className='contact-form-input contact-input-half mt-0'>
                    <input
                      type='email'
                      name='email'
                      value={email}
                      placeholder='Email Address *'
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      required
                    />
                  </div>
                </div>
                <div className='col-lg-5 col-md-12'>
                  <div className='contact-form-input contact-input-half mt-0'>
                    <input
                      type='number'
                      name='name'
                      value={phone}
                      maxLength='10'
                      placeholder='Phone Number *'
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                      required
                    />
                  </div>
                  <div className='contact-form-input contact-input-half'>
                    <div className='select'>
                      <select
                        id='sources'
                        name='service'
                        value={howDidYouHear}
                        className="select-service"
                        onChange={(e) => {
                          setHowDidYouHear(e.target.value);
                        }}>
                        <option value='hide'>How did you hear about us?</option>
                        <option value='SocialMedia'>Social Media</option>
                        <option value='WordOfMouth '>Referral</option>
                        <option value='Google'>Others</option>
                      </select>
                    </div>
                  </div>
                  <div className='contact-form-input contact-input-half'>
                    <div className='contact-form-input contact-input-full'>
                      <textarea
                        name='message2'
                        value={message2}
                        placeholder='Your Message *'
                        onChange={(e) => {
                          setMessage2(e.target.value);
                        }}
                        required></textarea>
                    </div>
                  </div>
                  <div className='contact-form-input contact-input-half'>
                    <div className='checkbox '>
                      <input
                        id='checkbox4'
                        className='styled'
                        type='checkbox'
                      />
                      <label for='checkbox4'>I'm not a robot</label>
                    </div>
                  </div>
                  <div className='contact-form-input contact-input-half'>
                    <div className='con-button text-center'>
                      <button
                        onClick={submitDetail}
                        className='btn-all btn-light-blue'
                        type='submit'>
                        Submit
                      </button>

                      <div className='error-msg'></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Container End--> */}
          </div>
        </div>
      </div>
      <div className='contact section con-back' id='contact'>
        <div className='container'>
          <div className='row justify-content-center align-items-center'>
            <div className='col-lg-7 col-md-12'>
              {/* <img src="assets/images/map.png" alt="map" /> */}

              {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.3808983089198!2d73.79519051436922!3d18.55685877290794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bfd814bea61d%3A0x1522a31767464c68!2sAlacrity%20India%20Innovation%20Centre!5e0!3m2!1sen!2sin!4v1638813001392!5m2!1sen!2sin" width="600" height="500" allowfullscreen="" loading="lazy" style={{ border: "none" }}></iframe> */}
              <iframe
                src='https://www.google.com/maps/embed/v1/place?q=place_id:ChIJJ3h54ya_wjsRcQEGyhZKb9g&key=AIzaSyDm5jTMEvn7FRy6aaZFf-rPy0qsvn2prPc'
                //allowfullscreen
                title='map'
                width='600'
                height='500'
                allowfullscreen=''
                loading='lazy'
                style={{ border: "none" }}></iframe>
              {/* 
              <LoadScript
                googleMapsApiKey='AIzaSyDm5jTMEvn7FRy6aaZFf-rPy0qsvn2prPc'
                googleMapsClientId="ChIJJ3h54ya_wjsRcQEGyhZKb9g">
                <GoogleMap
                  mapContainerStyle={mapStyles}
                  zoom={13}
                // center={defaultCenter}
                />
              </LoadScript> */}
              {/* <MapWithAMarker
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDm5jTMEvn7FRy6aaZFf-rPy0qsvn2prPc&v=3.exp&libraries=geometry,drawing,places"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `560px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                imagePath='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.3808983089198!2d73.79519051436922!3d18.55685877290794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bfd814bea61d%3A0x1522a31767464c68!2sAlacrity%20India%20Innovation%20Centre!5e0!3m2!1sen!2sin!4v1638813001392!5m2!1sen!2sin'
              /> */}
            </div>
            <div className='col-lg-5 col-md-12 contact-left text-left'>
              <div className='contact-information  d-flex  flex-column'>
                <div className='contact-us-single-item d-flex'>
                  <div className='contact-icon'>
                    <img src='assets/images/loc.png' alt='Calennder' />
                  </div>
                  <div className='working-hour '>
                    <div className='work-day'>Address</div>
                    <div className='work-time'>
                      Alacrity India Innovation Centre, Ideas to Impacts
                      Building. Pallod Farm Lane 3, Near Vijay Sales, Baner
                      Road, Pune - 411045
                    </div>
                  </div>
                </div>
                {/* <div className='contact-us-single-item d-flex'>
                  <div className='contact-icon'>
                    <img src='assets/images/phon.png' alt='Map' />
                  </div>
                  <div className='working-hour '>
                    <div className='work-day'>Phone</div>
                    <div className='work-time'>
                      (001) 123456789 - 234567891
                      <br />
                      (001) 987654324
                    </div>
                  </div>
                </div> */}
                <div className='contact-us-single-item d-flex'>
                  <div className='contact-icon'>
                    <img src='assets/images/email.png' alt='Telephone' />
                  </div>
                  <div className='working-hour '>
                    <div className='work-day'>Email</div>
                    <div className='work-time'>info@algoanalytics.com</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--Container End--> */}
        </div>
      </div>
      {/* <!-- Hero Section End  --> */}
      <Footer />
    </Fragment>
  );
};
export default Contact;
