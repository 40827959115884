import ButtonIconBorder from "./ButtonIconBorder";

const ContactUs = () => {
  return (
    <div className='contact-container'>
      <div className='contact-text-button'>
        <div className='contact-us-title'>Let's Connect</div>
        <div className='contact-us-subtitle'>Know more about our products and services, get a demo or schedule a meeting with us.
        </div>
        <div className='contact-us-button'>
          <a href='/contact'>
            <ButtonIconBorder
              name='Contact Us'
              svgPathAttributes={{
                fill: "currentColor",
                d: "M4 11.25a.75.75 0 0 0 0 1.5h9.25V18a.75.75 0 0 0 1.28.53l6-6a.75.75 0 0 0 0-1.06l-6-6a.75.75 0 0 0-1.28.53v5.25H4Z",
              }}
              styleClasses='justify-start'
            />
          </a>
        </div>
      </div>
      {/* image */}
      <div className='contact-us-image'>
        <img src='assets/images/contact-us.png' alt='contact-us' />
      </div>
    </div>
  );
};

export default ContactUs;
