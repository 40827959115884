/* eslint-disable eqeqeq */
import React from "react";
import Footer from "../Footer/Footer";
import Navigation from "../Navigation/Navigation";
import { Select } from 'antd';
import URLS from "../../constants";


// import api from '../../api';

const { Option } = Select;

class DemoApp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            demoApps: [],
            copyOfDemoApps: [],
            dataTypes: '',
            technology: '',
            industry: '',
            filterLoading: false,
        }
    }   

    getDemoApps = () => {
        // api.demoApp
        //     .getDemoApps()
        //     .then((result) => {
        //         // console.log("get demo apps ", result);
        //         if (result.status == '1') {
        //             console.log("get demo app data", result.data);
        //             if (result.data.length > 0) {
        //                 let data = [];
        //                 for (let d of result.data) {
        //                     if (d.status == 'active') {
        //                         data = [...data, d];
        //                     }
        //                 }
        //                 this.setState({
        //                     demoApps: data,
        //                     copyOfDemoApps: data
        //                 });
        //             }
        //         }
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     });
        let URL = URLS.getDemoApps;
        fetch(URL)
            .then(response => response.json())
            .then((data) => {
                let arr = [];
                for (let d of data) {
                    if (d.status == 'active') {
                        if(d.data_type.includes('Image') || d.data_type.includes('Video')) {
                            d.data_type = 'Image / Video';
                        }
                        arr = [...arr, d];
                    }
                }
                console.log('arr',arr);
                this.setState({
                    demoApps: arr,
                    copyOfDemoApps: arr
                });
            });
    }

    componentDidMount() {
        this.getDemoApps();
        this.setState({
            industry: this.props.filter
        }, () => {
            console.log(this.state.industry)
            document.body.scrollTop = 0;
            setTimeout(() => {
                this.getFilteredData();
            }, 500);
        });
        this.setState({
            dataTypes: (this.props.filterdata=='Video') ? 'Image / Video' : this.props.filterdata,
        }, () => {
            console.log(this.state.dataTypes)
            document.body.scrollTop = 0;

            setTimeout(() => {
                this.getFilteredData();
            }, 500);
        });
        this.setState({
            technology: this.props.filterservices
        }, () => {
            console.log(this.state.technology)
            document.body.scrollTop = 0;

            setTimeout(() => {
                this.getFilteredData();
            }, 500);
        })

    }

    handleChangeIndustry = (value) => {
        this.setState({
            industry: value,

            filterLoading: true,
        });
        setTimeout(() => {
            this.getFilteredData();
        }, 500);
    }

    handleChangeTechnology = (value) => {
        this.setState({
            technology: value,
            filterLoading: true,
        });
        setTimeout(() => {
            this.getFilteredData();
        }, 500);
    }

    handleChangeDataType = (value) => {
        this.setState({
            dataTypes: value,
            filterLoading: true,
        });
        console.log('value',value);
        setTimeout(() => {
            this.getFilteredData();
        }, 500);
    }

    getFilteredData = () => {
        let fiteredData = [];
        for (let singleData of this.state.copyOfDemoApps) {

            if (this.state.industry != "" && this.state.technology != "" && this.state.dataTypes != "") {
                if (singleData.industry.toLowerCase().includes(this.state.industry.toLowerCase())
                    && singleData.technology.toLowerCase().includes(this.state.technology.toLowerCase())
                    && singleData.data_type.toLowerCase().includes(this.state.dataTypes.toLowerCase())
                ) {
                    fiteredData = [...fiteredData, singleData];
                }
            } else if (this.state.industry != "" && this.state.technology) {
                if (singleData.industry.toLowerCase().includes(this.state.industry.toLowerCase())
                    && singleData.technology.toLowerCase().includes(this.state.technology.toLowerCase())) {
                    fiteredData = [...fiteredData, singleData];
                }
            } else if (this.state.industry != "" && this.state.dataTypes) {
                if (singleData.industry.toLowerCase().includes(this.state.industry.toLowerCase())
                    && singleData.data_type.toLowerCase().includes(this.state.dataTypes.toLowerCase())) {
                    fiteredData = [...fiteredData, singleData];
                }
            } else if (this.state.technology != "" && this.state.dataTypes) {
                if (singleData.technology.toLowerCase().includes(this.state.technology.toLowerCase())
                    && singleData.data_type.toLowerCase().includes(this.state.dataTypes.toLowerCase())) {
                    fiteredData = [...fiteredData, singleData];
                }
            } else if (this.state.industry != "") {
                if (singleData.industry.toLowerCase().includes(this.state.industry.toLowerCase())) {
                    fiteredData = [...fiteredData, singleData];
                }
            } else if (this.state.technology != "") {
                if (singleData.technology.toLowerCase().includes(this.state.technology.toLowerCase())) {
                    fiteredData = [...fiteredData, singleData];
                }
            } else if (this.state.dataTypes != "") {
                if (singleData.data_type.toLowerCase().includes(this.state.dataTypes.toLowerCase())) {
                    fiteredData = [...fiteredData, singleData];
                }
            } else {
                fiteredData = [...fiteredData, singleData];
            }
        }
        // console.log('fiteredData', fiteredData);
        setTimeout(() => {
            this.setState({
                demoApps: fiteredData,
                filterLoading: false,
            });
        }, 500);

        setTimeout(() => {
            this.setState({ filterLoading: false });
        }, 3000);

    }

    render() {

        return (

            <>
                <div id="filterdata"></div>

                {/* {setTimeout(() => { */}
                {/* <div className="loader">
                    <div className="loader-inner">
                    <img src="assets/images/loader.gif" alt="Loader GIF" className="loader-img" loop="infinite" />
                    </div>
                </div> */}
                {/* // }, 1000)} */}
                {/* <div id='filterdemo'></div> */}
                <Navigation />
                {/* <!-- Hero Section Start  --> */}
                <div className=" case-bg section-bottom" >
                    <div className="container h-100">
                        <div className="row d-flex align-items-center hero-inner justify-content-between h-100 flex-nowrap">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 serv-left">
                                <div className="serv-text text-center">
                                    <h2 >Explore Our Demo Apps</h2>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Container End--> */}
                    </div>
                </div>
                {/* <!-- Hero Section End  --> */}
                <div className="case-study-container section-bottom case-section">
                    <div className="container">
                        <div className="case-study-top d-flex align-items-center justify-content-between">
                            <div className="filter-main d-flex align-items-center">
                                <div className="filter-icon">
                                    <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M24.326 1.62849C24.2198 1.43758 24.0644 1.2786 23.876 1.16808C23.6876 1.05755 23.473 0.999514 23.2545 1H2.22282C2.00461 1.00002 1.79037 1.05842 1.60234 1.16916C1.41431 1.27989 1.25933 1.43892 1.15349 1.62975C1.04764 1.82058 0.994787 2.03625 1.00041 2.25439C1.00602 2.47254 1.06991 2.6852 1.18544 2.87033L9.2763 13.9219V25L16.2086 22.4292V13.9219L24.2654 2.91576L24.2957 2.87033C24.4112 2.68491 24.4749 2.47193 24.4803 2.25354C24.4856 2.03515 24.4323 1.81932 24.326 1.62849ZM14.6904 13.4221V21.3729L10.7907 22.823V13.4221L2.79831 2.51444H22.6828L14.6904 13.4221Z" fill="#86909F" stroke="#86909F" stroke-width="0.5" />
                                    </svg>
                                </div>
                                <Select defaultValue={this.state.industry} style={{ width: 300 }} onChange={this.handleChangeIndustry} value={this.state.industry}>
                                    <Option value="">Select Industry (All)</Option>
                                    <Option value="Manufacturing">Manufacturing</Option>
                                    <Option value="Banking & Finance" >Banking & Finance</Option>
                                    <Option value="Healthcare">Healthcare</Option>
                                    <Option value="Retail & E-Commerce">Retail & E-Commerce</Option>
                                    <Option value="Legal">Legal</Option>
                                </Select>
                            </div>
                            <div className="filter-main d-flex align-items-center">
                                <div className="filter-icon">
                                    <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M24.326 1.62849C24.2198 1.43758 24.0644 1.2786 23.876 1.16808C23.6876 1.05755 23.473 0.999514 23.2545 1H2.22282C2.00461 1.00002 1.79037 1.05842 1.60234 1.16916C1.41431 1.27989 1.25933 1.43892 1.15349 1.62975C1.04764 1.82058 0.994787 2.03625 1.00041 2.25439C1.00602 2.47254 1.06991 2.6852 1.18544 2.87033L9.2763 13.9219V25L16.2086 22.4292V13.9219L24.2654 2.91576L24.2957 2.87033C24.4112 2.68491 24.4749 2.47193 24.4803 2.25354C24.4856 2.03515 24.4323 1.81932 24.326 1.62849ZM14.6904 13.4221V21.3729L10.7907 22.823V13.4221L2.79831 2.51444H22.6828L14.6904 13.4221Z" fill="#86909F" stroke="#86909F" stroke-width="0.5" />
                                    </svg>
                                </div>
                                <Select
                                    defaultValue={this.state.technology}
                                    style={{ width: 300 }}
                                    onChange={this.handleChangeTechnology} value={this.state.technology}>
                                    <Option value="">Select Technology (All)</Option>
                                    <Option value="Deep learning / AI">Deep learning /AI</Option>
                                    <Option value="Computer vision (CV)">Computer vision (CV)</Option>
                                    <Option value="Natural language processing (NLP)">Natural language processing (NLP)</Option>
                                    <Option value="Cloud">Cloud</Option>
                                    <Option value="Data Analytics">Data Analytics</Option>
                                    <Option value="Web Applications">Web Applications</Option>
                                </Select>
                            </div>
                            <div className="filter-main d-flex align-items-center">
                                <div className="filter-icon">
                                    <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M24.326 1.62849C24.2198 1.43758 24.0644 1.2786 23.876 1.16808C23.6876 1.05755 23.473 0.999514 23.2545 1H2.22282C2.00461 1.00002 1.79037 1.05842 1.60234 1.16916C1.41431 1.27989 1.25933 1.43892 1.15349 1.62975C1.04764 1.82058 0.994787 2.03625 1.00041 2.25439C1.00602 2.47254 1.06991 2.6852 1.18544 2.87033L9.2763 13.9219V25L16.2086 22.4292V13.9219L24.2654 2.91576L24.2957 2.87033C24.4112 2.68491 24.4749 2.47193 24.4803 2.25354C24.4856 2.03515 24.4323 1.81932 24.326 1.62849ZM14.6904 13.4221V21.3729L10.7907 22.823V13.4221L2.79831 2.51444H22.6828L14.6904 13.4221Z" fill="#86909F" stroke="#86909F" stroke-width="0.5" />
                                    </svg>
                                </div>
                                <Select
                                    style={{ width: 300 }}
                                    placeholder="Select Data Type (All)"
                                    value={this.state.dataTypes}
                                    onChange={this.handleChangeDataType}
                                >
                                    <Option value="">Select Data Type (All)</Option>
                                    <Option value="Image / Video">Image/Video</Option>
                                    <Option value="Text">Text</Option>
                                    {/* <Option value="Video">Video</Option> */}
                                    <Option value="Sound / Audio">Sound/Audio</Option>
                                    <Option value="Numerical">Numerical</Option>
                                </Select>
                            </div>

                            <a href="/demoapp" className="btn-all btn-light-blue ">View All</a>
                        </div>
                        <div className="case-card-main">
                            {/* <div className="case-small-text">{this.state.demoApps.length} demos for manufacturing sector</div> */}
                            {this.state.filterLoading == true && (
                                <img src='assets/images/loading.gif' alt="" />
                            )}
                            <div className="demo-card-list row">
                                {this.state.filterLoading == false && this.state.demoApps && this.state.demoApps.map((item, index) => {
                                    return (
                                        <div className="demo-card-main col-lg-4 col-md-6 col-sm-12" key={index}>
                                            <div className="demo-card-inner text-left">
                                                <div className="demo-image">
                                                    <img src={item.demo_app_image ? item.demo_app_image : "assets/images/image_not_available.png"} className="img-fluid" alt="img" />
                                                    <div className="demo-tag-main d-flex align-items-center justify-content-center">
                                                        <span>{item.industry}</span>
                                                        <span>{item.technology}</span>
                                                        <span>{item.data_type}</span>
                                                    </div>
                                                </div>
                                                <div className="demo-details">
                                                    <h4>{item.demo_app_name}</h4>
                                                    <p>{item.short_description.length > 90 ? item.short_description.slice(0, 90) + '..' : item.short_description}</p>
                                                    <div className="demo-btn d-flex align-items-center justify-content-between">
                                                        {item.try_demo && (
                                                            // <a href={item.try_demo.includes('https://') ? item.try_demo : `https://${item.try_demo}`} target="_blank">
                                                            <a href={item.try_demo} target="_blank" rel="noreferrer">
                                                                <button className="try-btn check-our-demo-btn">Check our Demo</button>
                                                            </a>
                                                        )}
                                                        {/* {item.play_demo && (
                                                            <a target="_blank" href={item.play_demo.includes('https://') ? item.play_demo : `https://${item.play_demo}`} className="demo-play-btn d-flex align-items-center">Play Demo Video
                                                                <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="ml-1">
                                                                    <path d="M8 1L13 5.91968L8 10.8394M0 5.9502L12 5.95019" stroke="#537EDF" stroke-width="1.5" />
                                                                </svg>
                                                            </a>
                                                        )} */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
               {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className="back-top" href="#"><i className="fa fa-angle-up"></i></a>

            </>
        );
    }
}

export default DemoApp;