
const Footer = () => {
  const footerLinks = [
    {
      title: "Services",
      links: [
        {
          name: "Cloud Deploymemts",
          link: "/cloud-deployment",
        },
        {
          name: "AI/ML Solutions",
          link: "/ai-ml-solutions",
        },
        {
          name: "Generative AI",
          link: "/generative-ai",
        },
      ],
    },
    {
      title: "Products",
      links: [
        {
          name: "Aksha",
          link: "/products#Aksha",
        },
        {
          name: "aQuality",
          link: "/products#aQuality",
        },
        {
          name: "AlgoFabric",
          link: "/products#AlgoFabric",
        },
      ],
    },
    {
      title: "Emerging Tech",
      links: [
        {
          name: "Digital Twin",
          link: "/emergingtech#Digital Twin",
        },
        {
          name:"Federated Learning",
          link:"/emergingtech#Federated Learning"
        },
        {
          name: "Quantum Computing",
          link: "/emergingtech#Quantum Computing",
        }
      ],
    },
    {
      title: "Tech MarketPlace",
      links: [
        {
          name: "Onestop.ai",
          link: "https://onestop.ai/",
          target: "_blank",
        },
      ],
    },
    {
      title: "Resources",
      links: [
        {
          name: "Case Studies",
          link: "/resources#Case Studies",
        },
        {
          name: "Videos",
          link: "/resources#Videos",
        },
        {
          name: "News Releases",
          link: "/resources#News Releases",
        },
        {
          name: "Whitepapers",
          link: "/resources#Whitepapers",
        },
        {
          name: "Blogs",
          link: "/resources#Blogs",
        }
      ],
    },
    {
      title: "About",
      links: [
        {
          name: "About Us",
          link: "/about",
        },
        {
          name: "Leadership Team",
          link: "/about#team",
        },
        {
          name: "Careers",
          link: "/careers",
        },
        {
          name: "Contact Us",
          link: "/contact",
        },
      ],
    },
  ];
  return (
    <div className='footer-inner'>
      <div className='container'>
        <div className='footer-main'>
          <a href='/' style={{maxWidth:"100px"}}>
            <img
              src='assets/images/Algo_Analytics.png'
              alt='AlgoAnalytics'
              className='footer-logo'
            />
          </a>
          {footerLinks.map((footerLinks,index) => (
            <div className="footer-links" key={index}>
              <div className='footer-links-title'>{footerLinks.title}</div>
              {footerLinks.links.map((links,linksIndex) => (
                <div className='footer-inner-links-title' key={linksIndex}>
                  <a href={links.link} target={links.target}>{links.name}</a>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="footer-divider"></div>
        <div className='footer-bottom-section'>
          <div className='footer-bottom-left'>
            <div className='footer-bottom-left-title'>Follow Us</div>
            <div className='footer-bottom-left-icons'>
              <a
                target='_blank'
                href='https://twitter.com/AlgoanalyticsIn'
                className='footer-inner-links-title' rel="noreferrer">
                <i class="fa-brands fa-x-twitter"></i>
              </a>
              <a
                target='_blank'
                href='https://www.linkedin.com/company/algoanalytics/'
                className='footer-inner-links-title'
                rel='noreferrer'>
                <i className='fa-brands fa-linkedin'></i>
              </a>
              <a
                target='_blank'
                href="https://www.instagram.com/algoanalyticsin/"
                className='footer-inner-links-title'
                rel='noreferrer'>
                  <i class="fa-brands fa-instagram"></i>
              </a>
            </div>
          </div>
          <div className='footer-bottom-right'>
            <div className='footer-bottom-right-links'>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href='#'>Terms & Conditions</a>
              <a href='/privacypolicy'>Privacy Policy</a>
            </div>
            <div className='footer-bottom-right-text'>
              © 2024 by AlgoAnalytics Pvt. Ltd. All rights reserved.
            </div>
          </div>
        </div>
      </div>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a className='back-top' href='#'>
        <i className='fa fa-angle-up'></i>
      </a>
    </div>
  );
};
export default Footer;
