const TranslateGoogle = () => {
    // Languages list
    const languages = [
      {
        code: "en",
        svg: (
          <svg
            className="w-full aspect-video"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 480"
          >
            <path fill="#012169" d="M0 0h640v480H0z" />
            <path
              fill="#FFF"
              d="m75 0l244 181L562 0h78v62L400 241l240 178v61h-80L320 301L81 480H0v-60l239-178L0 64V0h75z"
            />
            <path
              fill="#C8102E"
              d="m424 281l216 159v40L369 281h55zm-184 20l6 35L54 480H0l240-179zM640 0v3L391 191l2-44L590 0h50zM0 0l239 176h-60L0 42V0z"
            />
            <path fill="#FFF" d="M241 0v480h160V0H241zM0 160v160h640V160H0z" />
            <path fill="#C8102E" d="M0 193v96h640v-96H0zM273 0v480h96V0h-96z" />
          </svg>
        ),
      },
      {
        code: "ja",
        svg: (
          <svg
            className="w-full aspect-video"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 480"
          >
            <defs>
              <clipPath id="flagJp4x30">
                <path fillOpacity=".7" d="M-88 32h640v480H-88z" />
              </clipPath>
            </defs>
            <g
              fillRule="evenodd"
              strokeWidth="1pt"
              clipPath="url(#flagJp4x30)"
              transform="translate(88 -32)"
            >
              <path fill="#fff" d="M-128 32h720v480h-720z" />
              <circle
                cx="523.1"
                cy="344.1"
                r="194.9"
                fill="#bc002d"
                transform="translate(-168.4 8.6) scale(.76554)"
              />
            </g>
          </svg>
        ),
      },
    ]
  
    // Hidden Translate div ref
    // const hiddenTranslateRef = useRef<HTMLDivElement>(null)
    let hiddenTranslateSelectElement = document.querySelector(
      "select.goog-te-combo"
    )
  
    // On Language Change
    const onLanguageChange = (languages) => {
      console.log(hiddenTranslateSelectElement)
      if (!hiddenTranslateSelectElement) {
        console.log("Element not found")
        hiddenTranslateSelectElement = document.querySelector(
          "select.goog-te-combo"
        )
        return
      }
      console.log(hiddenTranslateSelectElement.value, languages.code)
      // Set Language
      hiddenTranslateSelectElement.value = languages.code
      if (!hiddenTranslateSelectElement.value) return
      console.log(hiddenTranslateSelectElement.value)
      // Dispatch change event
      hiddenTranslateSelectElement.dispatchEvent(new Event("change"))
    }
  
    return (
      <>
        {/* <div
          style={{ display: "none" }}
          id="google_translate_element"
          ref={hiddenTranslateRef}
        ></div> */}
        {/* Dropdown  */}
        <div className="translate-menu">
          <button className="translate-button">
            <div className="btn-title">
              <div>Language</div></div>
            {/* Dropdown Icon  */}
            <svg
              style={{height:'100%',marginLeft:'0.5rem',padding:'0.15rem'}}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path fill="currentColor" d="m7 10l5 5l5-5z" />
            </svg>
            <ul className="lang-list">
            {languages
              // .filter((lang) => lang.code !== hiddenTranslateSelectElement?.value)
              .map((lang, i) => (
                <button 
                  key={i}
                  className="lang-button"
                  onClick={() => {
                    console.log("clicked")
                    onLanguageChange(lang)
                  }}
                >
                  <div style={{width:'32px'}}>{lang.svg}</div>
                  <div style={{marginLeft:'0.5rem'}} translate="no">
                    {lang.code.toUpperCase()}
                  </div>
                </button>
              ))}
          </ul>
          </button>
        </div>
      </>
    )
  }
  
  export default TranslateGoogle
  