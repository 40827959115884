import ContactUs from "./Common/ContactUs";
import Footer from "./Footer/Footer";
import Navigation from "./Navigation/Navigation";
import { useState,useEffect,useRef } from "react";
import $ from "jquery";
import { Helmet } from "react-helmet";

const SingleService = (props) => {
  const [count, setCount] = useState(0);

  const goDown = () => {
    let countVal = Number(count) + 1;
    setCount(countVal);
    var windowScroll = $("body").scrollTop();
    console.log("window scrolltop", windowScroll);
    console.log("countval", countVal);

    if (countVal === 1) {
      $("body").animate({ scrollTop: 626 }, "slow");
    } else if (countVal === 2) {
      $("body").animate({ scrollTop: 1342 }, "slow");
    } else if (countVal === 3) {
      $("body").animate({ scrollTop: 1955 }, "slow");
    } else if (countVal === 4) {
      $("body").animate({ scrollTop: 2497 }, "slow");
    } else if (countVal === 5) {
      $("body").animate({ scrollTop: 3096 }, "slow");
    } else if (countVal === 6) {
      $("body").animate({ scrollTop: 3380 }, "slow");
    } else {
      let scroll = Number($("#scrollTop").val());
      // console.log('scroll',scroll);
      if (scroll < 300) {
        setCount(0);
      }
      $("body").animate({ scrollTop: 4000 }, "slow");
    }
  };

  const iframeRef = useRef(null);
  const anchorRef = useRef(null);

  useEffect(() => {
    const currentAnchor = anchorRef.current;
    // Function to handle visibility change
    const handleVisibilityChange = () => {
      if (document.hidden) {
        // Document is now hidden, pause the YouTube video
        if (iframeRef.current) {
          iframeRef.current.src = iframeRef.current.src.replace('autoplay=1', 'autoplay=0');
        }
      }
    };

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // Video is in view, autoplay it
          if (iframeRef.current) {
            iframeRef.current.src = iframeRef.current.src.replace('autoplay=0', 'autoplay=1');
          }
        } else {
          // Video is not in view, pause it
          if (iframeRef.current) {
            iframeRef.current.src = iframeRef.current.src.replace('autoplay=1', 'autoplay=0');
          }
        }
      },
      {
        rootMargin: '0px',
        threshold: 0.1,
      }
    );

    if (currentAnchor) {
      observer.observe(anchorRef.current);
    }
  
    // Listen for visibility changes
    window.addEventListener('visibilitychange', handleVisibilityChange);
  
    // Cleanup function to remove the event listener
    return () => {
      if (currentAnchor) {
        observer.unobserve(currentAnchor);
      }
      window.removeEventListener('visibilitychange', handleVisibilityChange)
    };
  },);

  return (
    <>
    <Helmet>
        <title>{props.metaTitle}</title>
        <meta
          name='description'
          content={props.metaDescription}
        />
        <meta
          name='keywords'
          content='Data Analytics, Cloud Deployments, AI/ML Solutions, Generative AI'
        />
        <meta name='robots' content='index, follow' />
      </Helmet>
      <div className='loader'>
        <div className='loader-inner'>
          <img
            src='assets/images/loader.gif'
            alt='Loader GIF'
            className='loader-img'
            loop='infinite'
          />
        </div>
      </div>
      <input type='hidden' id='scrollTop' value='0' />
      <div className='single-service-page'>
        <Navigation />
        <div style={{ padding: "5rem 0" }}></div>
        <section className='title-image-split'>
          <div className='left-pane'>
            <h3 className='title'>{props.title}</h3>
            <div className='description'>{props.description}</div>
          </div>
          <div className='right-pane'>
            <img className='image' src={props.image} alt={props.altText} />
          </div>
        </section>
        {/* down arrow button */}
        <div className='go-down next' onClick={goDown} id='go-down'>
          <span>
            <i className='fa fa-angle-down'></i>
          </span>
        </div>
        {props.iframeSrcLink ? (
          <section className='service-video' ref={anchorRef}>
            <iframe
              ref={iframeRef}
              width='914'
              height='514'
              src={props.iframeSrcLink}
              title='AlgoAnalytics: Our Services in Generative AI'
              frameborder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              referrerpolicy='strict-origin-when-cross-origin'
              allowfullscreen></iframe>
          </section>
        ) : null}
        <section className='core-values'>
          <div>
            <div className='core-title'>Core Offerings </div>
            {props.coreDetails ? (
              <div className='core-values-grid'>
                {props.coreDetails.map((data, i) => (
                  <div className='core-values-grid-item' key={i}>
                    <div className='core-values-grid-item-title'>
                      {data.coreTitle}
                    </div>
                    <div className='core-values-grid-item-description'>
                      {data.coreDescription}
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
            {props.flowchartDetails ? (
              <div className='flowchart-section'>
                <div className='flowchart-grid'>
                  {props.flowchartDetails.map((item, index) => {
                    return (
                      <>
                        <h3 className='flowchart-title'>
                          {item.flowchartTitle}
                        </h3>
                        <div className='flowchart-subtitle'>
                          {item.flowchartSubtitle}
                        </div>
                        <div className='flowchart-image' key={index}>
                          {item.flowchartStyle ? (
                            <img
                              style={{ maxWidth: item.flowchartStyle }}
                              src={item.flowchartImage}
                              className='image'
                              alt= {item.altText}
                            />
                          ) : (
                            <img
                              src={item.flowchartImage}
                              className='image'
                              alt= {item.altText}
                            />
                          )}
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            ) : null}
          </div>
          <div>
            <div className='value-title'>Value Delivered</div>
            <div className='core-values-grid'>
              {props.valueDetails.map((data, i) => (
                <div className='value-delivered'>
                  <i class='fa-solid fa-circle-check fa-2xl'></i>
                  <div className='values-grid-item-description'>
                    {data.value}
                  </div>
                </div>
              ))}
            </div>
          </div>
          {props.techStack ? (
            <div className='tech-stack-section'>
              <h3 className='small-heading text-center'>Tech stack</h3>
              <div className='tech-stack-grid'>
                {props.techStack.map((item, index) => {
                  return (
                    <>
                      <div href='/' className='tech-stack-logo' key={index}>
                        {item.text ? (
                          <div className='tech-stack-text'>{item.text}</div>
                        ) : (
                          <img
                            src={item.techImage}
                            className='image'
                            alt='tech-stack-img'
                            style={{ padding: item.padding }}
                          />
                        )}
                      </div>
                      <div className='tech-logo-divider'></div>
                    </>
                  );
                })}
              </div>
            </div>
          ) : null}
        </section>

        <ContactUs />
        <Footer />
      </div>
    </>
  );
};
export default SingleService;
