
const OurStrength = () => {

  const listItems = [
    {
      title: "Our solutions are built on a robust foundation of mathematical and statistical principles, ensuring accuracy and reliability.",
    },
    {
      title: "Our core team comprises seasoned professionals with deep expertise in AI and machine learning, driving innovation and excellence.",
    },
    {
      title: "From classical machine learning to cutting-edge deep learning and generative AI, our expertise spans the entire AI/ML spectrum.",
    },
    {
      title: "Our panel of domain experts ensures that our AI/ML solutions are tailored to meet the unique challenges of each industry we serve.",
    },
    {
      title: "With over five years of successful AI/ML projects globally, we have a proven track record of delivering impactful solutions.",
    },
  ];

  return (
    <div className='hero-section serv-page-hero serv-bg' id=''>
      <div className='container h-100'>
        <div className='common-page-main-section' style={{ padding: "2rem" }}>
          <div className='common-page-hero-title'>
          Our Strengths in AI/ML: Expertise in Data Analytics Across Industries</div>
          <div className='common-page-hero-subtitle'>
          Leading AI/ML Solutions for Industry-Specific Challenges
          </div>
        </div>
        <div className='row d-flex align-items-center hero-inner justify-content-between h-50'>
          <div className='col-lg-6 col-md-12 col-sm-12 col-xs-12 serv-left'>
            <div className='text-center'>
              <img
                src='assets/images/serv-hero-image.png'
                alt="Img of a company employees working in AI and futuristic enviorment"
                className='item-image'
              />
            </div>
          </div>
          <div className='col-lg-6 col-md-12 col-sm-12 col-xs-12 serv-right'>
            <div className='serv-list'>
              {listItems.map((item, index) => (
                <div className='serv-list-item'>
                  <div className='serv-list-item-title'>
                    <img src='assets/images/bullseye.png' width={30} height={30} alt='arrow' />
                    <div style={{marginTop:"1rem"}}>{item.title}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurStrength;
