import { Fragment } from "react";
import logo1 from "../Navigation/new-logo.png";
import Translatebutton from "../Translatebutton";

const NavData = [
  {
    title: "Services",
    navLink: "/services",
    links: [
      {
        name:'Overview',
        link:'/services',
      },
      {
        name: "Cloud Deployments",
        link: "/cloud-deployment",
      },
      {
        name: "AI/ML Solutions",
        link: "/ai-ml-solutions",
      },
      {
        name: "Generative AI",
        link: "/generative-ai",
      },
    ],
  },
  {
    title: "Emerging Tech",
    navLink: "/emergingtech",
    links: [
      {
        name: "Digital Twin",
        link: "/emergingtech#Digital Twin",
      },
      {
        name: "Federated Learning",
        link: "/emergingtech#Federated Learning",
      },
      {
        name: "Quantum Computing",
        link: "/emergingtech#Quantum Computing",
      },
    ],
  },
  {
    title: "Tech MarketPlace",
    links: [
      {
        name: "Onestop.ai",
        link: "https://onestop.ai/",
        target: "_blank",
      },
    ],
  },
  {
    title: "Products",
    navLink: "/products",
    links: [
      {
        name: "Aksha",
        link: "/products#Aksha",
      },
      {
        name: "aQuality",
        link: "/products#aQuality",
      },
      {
        name: "AlgoFabric",
        link: "/products#AlgoFabric",
      },
    ],
  },
  {
    title: "Resources",
    navLink: "/resources",
    links: [
      {
        name: "Case Studies",
        link: "/resources#Case Studies",
      },
      {
        name: "Videos",
        link: "/resources#Videos",
      },
      {
        name: "News Releases",
        link: "/resources#News Releases",
      },
      {
        name: "Whitepapers",
        link: "/resources#Whitepapers",
      },
      {
        name: "Blogs",
        link: "/resources#Blogs",
      },
    ],
  },
  {
    title: "About Us",
    navLink: "/about",
    links: [
      {
        name:"Who are we",
        link:"/about",
      },
      {
        name: "Leadership Team",
        link: "/about#team",
      },
      {
        name: "Careers",
        link: "/careers",
      },
      {
        name: "Contact Us",
        link: "/contact",
      },
    ],
  },
];

const Navigation = () => {
  return (
    <Fragment>
      {/* sticky-top */}
      <nav className='navbar navbar-expand-lg  sticky-top navbar-light home-nav'>
        <div className='nav-container d-flex flex-wrap justify-content-between'>
        <div className='nav-logos'>
          <a href='/'>
            <img
              src={logo1}
              className='logo-image'
              alt='Logo'
              style={{ maxWidth: 100 }}
            />
          </a>
            <a href='/' style={{ textAlign:"left", color:"black"}}>
              <img
                src='assets/images/logo/logo-img4.png'
                className='logo-image'
                alt='Logo'
                style={{ maxWidth: 80 }}
              />
              <div></div>
              <span className='text-xs md:text-sm'>Funded Company</span>
            </a>
          </div>
          <button
            className='navbar-toggler'
            data-target='#navigation'
            data-control='navigation'
            data-toggle='collapse'>
            <span className='navbar-toggler-icon'></span>
          </button>
          <div className='collapse navbar-collapse' id='navigation'>
            <ul className='nav navbar-nav  ml-auto align-items-center'>
              {NavData.map((data, index) => {
                return (
                  <li
                    className='nav-item has-sub-menu dropdown drop-desktop'
                    key={index}>
                    <a
                      href={data.navLink}
                      className='nav-link dropdown-toggle d-none d-sm-none d-xs-none d-md-block d-lg-block d-xl-block'>
                      {data.title}
                    </a>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      href='#'
                      className='nav-link dropdown-toggle  d-block d-sm-block d-xs-block d-md-none d-lg-none d-xl-none'>
                      {data.title}
                    </a>
                    <div
                      className='sub-menu-wrapper dropdown-menu'
                      style={{
                        padding: 0,
                        // width: 400,
                      }}>
                      <div className='sub-menu-row d-flex justify-content-center'>
                        <ul className='sub-menu' style={{ lineHeight: "2.5" }}>
                          {data.links.map((links, index) => (
                            <li className='menu-link' key={index}>
                              <a
                                href={links.link}
                                target={links.target}
                                className='menu-detail'>
                                <h5>{links.name}</h5>
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </li>
                );
              })}
              <li className='nav-item has-sub-menu dropdown drop-desktop'>
                <Translatebutton />
              </li>
            </ul>
            {/* <div className='mobile-lang-btn'>
              <Translatebutton />
            </div> */}
          </div>
        </div>
        {/* <div className="desktop-lang-btn">
        <Translatebutton />
        </div> */}
      </nav>
    </Fragment>
  );
};
export default Navigation;
